<script setup lang="ts">
import api from "@virgodev/bazaar/functions/api";
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, watch } from "vue";
import { RouterLink, RouterView, useRoute } from "vue-router";
import BaseAlert from "@/components/BaseAlert.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import HowToUse from "@/components/HowToUse.vue";
import ParentsAreaPasswordPrompt from "@/components/ParentsAreaPasswordPrompt.vue";
import ScrollbarSizeDetector from "@/components/ScrollbarSizeDetector.vue";
import TransitionSlide from "@/components/TransitionSlide.vue";
import getNavIcon from "@/methods/get_nav_icon";
import mmwSections from "@/mmw_sections.json";
import { parentsAreaRoutes } from "@/router";
import { useStore } from "@/stores";

const route = useRoute();
const store = useStore();
const {
  firstRouteReached,
  loggedIn,
  userData,
  desktopPaddingVerticalAmount,
  showReloadMessage,
} = storeToRefs(store);

const parentPasswordEntered = ref(false);

const sidebarUl = ref<HTMLElement | null>(null);
const sidebarUlCanScrollDown = ref(false);
const sidebarUlCanScrollUp = ref(false);

const footer = ref<HTMLElement | null>(null);
const showFooterNavigation = ref(true);

const parentsAreaInterface = computed(() => {
  return (
    parentsAreaRoutes.includes(String(route.name)) ||
    (route.name === "questionnaire" && userData.value.has_visited_stage)
  );
});

watch(
  () => route.name,
  () => {
    showFooterNavigation.value = ![
      "my_music_room",
      "my_musician",
      "my_rooms",
      "music_games",
      "music_game_musical_memory_match",
      "music_game_music_studio_jr",
      "music_store",
      "music_store_collection",
      "music_store_category",
      "music_store_instrument",
    ].includes(String(route.name));
  },
);

if (CSS.supports("font", "-apple-system-body")) {
  // Safari sometimes has a rendering issue with the glow around the active
  // navigation item(s). This code helps to mitigate that.

  watch(
    () => route.path,
    () => {
      if (sidebarUl.value) {
        sidebarUl.value.style.display = "none";
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        sidebarUl.value.offsetHeight;
        sidebarUl.value.style.display = "";
      }

      if (footer.value) {
        footer.value.style.display = "none";
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        footer.value.offsetHeight;
        footer.value.style.display = "";
      }
    },
  );
}

function getFooterNavigationSectionLink(sectionSlug: string) {
  let name = "lessons";

  if (route.name === "worksheets") {
    name = "worksheets";
  } else if (route.name === "quizzes") {
    name = "quizzes";
  } else if (
    route.name === "jam_alongs" &&
    ["piano", "drums", "ukulele", "songland"].includes(sectionSlug)
  ) {
    name = "jam_alongs";
  }

  return {
    name,
    params: {
      sectionSlug,
    },
  };
}

const ratioChecker = ref<HTMLDivElement | null>(null);

const ratioThresholds = {
  min: 1.28333,
  max: 4.19637,
};

const appDiv = document.getElementById("app") as HTMLDivElement;
const appDivHeight = ref("0px");
const resizeNeeded = ref<"mobile" | "safari_sidebar" | null>(null);

const interfaceType = computed(() => {
  if (
    firstRouteReached.value &&
    (loggedIn.value || route.name === "logout") &&
    ![
      "login",
      "signup",
      "email_verification",
      "onboard",
      "onboard_school",
    ].includes(String(route.name)) &&
    !(route.name === "questionnaire" && !userData.value.has_visited_stage)
  ) {
    return "full";
  }

  return "simple";
});

const appLaunchDataStatus = ref<"loading" | "loaded" | "error">("loading");

watch(appLaunchDataStatus, () => {
  if (appLaunchDataStatus.value === "loaded") {
    watch(
      firstRouteReached,
      () => {
        if (firstRouteReached.value) {
          appDiv.classList.remove("first-route-not-reached-content");
        }
      },
      { immediate: true },
    );

    watch(
      interfaceType,
      async () => {
        await nextTick();

        if (interfaceType.value === "full") {
          sidebarUl.value?.addEventListener("scroll", () => {
            if (sidebarUl.value) {
              sidebarUlCanScrollDown.value =
                sidebarUl.value.scrollTop + sidebarUl.value.offsetHeight !==
                sidebarUl.value.scrollHeight;
              sidebarUlCanScrollUp.value = sidebarUl.value.scrollTop > 0;
            }
          });

          new ResizeObserver(() => {
            if (sidebarUl.value) {
              if (sidebarUl.value.scrollHeight > sidebarUl.value.clientHeight) {
                sidebarUlCanScrollDown.value =
                  sidebarUl.value.scrollTop + sidebarUl.value.offsetHeight !==
                  sidebarUl.value.scrollHeight;
                sidebarUlCanScrollUp.value = sidebarUl.value.scrollTop > 0;
              } else {
                sidebarUlCanScrollDown.value = false;
                sidebarUlCanScrollUp.value = false;
              }
            }

            if (ratioChecker.value) {
              const ratio =
                ratioChecker.value.offsetWidth /
                ratioChecker.value.offsetHeight;

              if (
                ratio >= ratioThresholds.min &&
                ratio <= ratioThresholds.max
              ) {
                resizeNeeded.value = null;
                desktopPaddingVerticalAmount.value = "0px";
              } else if (
                /(Android|iPad|iPhone|iPod)/g.test(navigator.userAgent) ||
                (navigator.platform === "MacIntel" &&
                  navigator.maxTouchPoints > 1)
              ) {
                if (
                  /iPad/g.test(navigator.userAgent) &&
                  window.matchMedia("(orientation: landscape)").matches
                ) {
                  resizeNeeded.value = "safari_sidebar";
                } else {
                  resizeNeeded.value = "mobile";
                }
              } else if (ratio < ratioThresholds.min) {
                desktopPaddingVerticalAmount.value = `${
                  (ratioChecker.value.offsetHeight /
                    ratioChecker.value.offsetWidth) *
                  155
                }px`;
              } else {
                desktopPaddingVerticalAmount.value = "0px";
              }
            }
          }).observe(ratioChecker.value as HTMLDivElement);
        }
      },
      { immediate: true },
    );
  }
});

async function loadAppLaunchData() {
  appLaunchDataStatus.value = "loading";

  const responseData = await api({ url: "app-launch/" });

  if (responseData.status === 200) {
    store.howToUseVideoIds = responseData.body.how_to_use_video_ids;

    if (responseData.body.user_data === undefined) {
      store.signupEmailSubscribeCheckedByDefault =
        responseData.body.signup_email_subscribe_checked_by_default;
    } else {
      store.logIn(responseData.body.user_data);

      if (responseData.body.active_student !== undefined) {
        store.activeStudent = responseData.body.active_student;
      }
    }

    new ResizeObserver(() => {
      appDivHeight.value = `${appDiv.clientHeight}px`;
    }).observe(appDiv);

    appDiv.classList.remove("app-not-launched-content");
    appLaunchDataStatus.value = "loaded";
  } else {
    appLaunchDataStatus.value = "error";
  }
}

if (
  /(iPad|iPhone|iPod)/g.test(navigator.userAgent) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
) {
  // This appears to be an iOS device. Some browsers on iOS have a
  // "pull-to-refresh" feature and, when used, it can cause the website to get
  // stuck in a state of having a large gap above the content. The following
  // block of code fixes that.

  (function fixGap(i) {
    document.documentElement.scrollTop = 1;

    i -= 1;

    if (i) {
      setTimeout(() => {
        fixGap(i);
      }, 100);
    }
  })(5);
}

loadAppLaunchData();
</script>

<template>
  <TransitionSlide mode="out-in">
    <BaseAlert v-if="showReloadMessage" class="reload-message">
      My Music Workshop has been updated. Please reload the page.
    </BaseAlert>
  </TransitionSlide>

  <div v-if="appLaunchDataStatus === 'error'" class="launch-error-content">
    <BaseAlert variant="danger">
      Unable to communicate with the server. Please check your connection.
    </BaseAlert>

    <BaseButton @click="loadAppLaunchData">Try Again</BaseButton>
  </div>

  <BaseSpinner v-else-if="!firstRouteReached" preset="large" />

  <template v-else-if="interfaceType === 'full'">
    <div :inert="resizeNeeded !== null">
      <nav
        class="sidebar"
        :class="{
          'sidebar-ul-can-scroll-down': sidebarUlCanScrollDown,
          'sidebar-ul-can-scroll-up': sidebarUlCanScrollUp,
        }"
      >
        <ul ref="sidebarUl" class="list-unstyled">
          <template
            v-if="
              ['lessons', 'worksheets', 'quizzes', 'jam_alongs'].includes(
                String($route.name),
              )
            "
          >
            <li>
              <RouterLink
                :to="{
                  name: 'lessons',
                  params: {
                    sectionSlug: $route.params.sectionSlug,
                  },
                }"
                :class="{ active: $route.name === 'lessons' }"
              >
                <img :src="getNavIcon('lessons')" alt="Lessons" />
              </RouterLink>
            </li>
            <li>
              <RouterLink
                :to="{
                  name: 'worksheets',
                  params: {
                    sectionSlug: $route.params.sectionSlug,
                  },
                }"
                :class="{ active: $route.name === 'worksheets' }"
              >
                <img :src="getNavIcon('worksheets')" alt="Worksheets" />
              </RouterLink>
            </li>
            <li>
              <RouterLink
                :to="{
                  name: 'quizzes',
                  params: {
                    sectionSlug: $route.params.sectionSlug,
                  },
                }"
                :class="{ active: $route.name === 'quizzes' }"
              >
                <img :src="getNavIcon('quizzes')" alt="Quizzes" />
              </RouterLink>
            </li>
            <li><HowToUse /></li>
            <li
              v-if="
                ['piano', 'drums', 'ukulele', 'songland'].includes(
                  String($route.params.sectionSlug).toLowerCase(),
                )
              "
            >
              <RouterLink
                :to="{
                  name: 'jam_alongs',
                  params: {
                    sectionSlug: $route.params.sectionSlug,
                  },
                }"
                :class="{ active: $route.name === 'jam_alongs' }"
              >
                <img
                  :src="getNavIcon('jam-along-mp3s')"
                  alt="Jam Along mp3's"
                />
              </RouterLink>
            </li>
          </template>

          <template v-else>
            <li>
              <RouterLink
                :to="{ name: 'my_musician' }"
                :class="{ active: $route.name === 'my_musician' }"
              >
                <img :src="getNavIcon('my-musician')" alt="My Musician" />
              </RouterLink>
            </li>
            <li data-new-student-tour="my_music_room">
              <RouterLink
                :to="{ name: 'my_music_room' }"
                :class="{
                  active: ['my_music_room', 'my_rooms'].includes(
                    String($route.name),
                  ),
                }"
              >
                <img :src="getNavIcon('my-music-room')" alt="My Music Room" />
              </RouterLink>
            </li>
            <li data-new-student-tour="music_store">
              <RouterLink
                :to="{ name: 'music_store' }"
                :class="{
                  active: [
                    'music_store',
                    'music_store_collection',
                    'music_store_category',
                    'music_store_instrument',
                  ].includes(String($route.name)),
                }"
              >
                <img :src="getNavIcon('music-store')" alt="The Music Store" />
              </RouterLink>
            </li>
            <li>
              <RouterLink
                :to="{ name: 'music_games' }"
                :class="{
                  active: String($route.name).startsWith('music_game'),
                }"
              >
                <img :src="getNavIcon('music-games')" alt="Music Games" />
              </RouterLink>
            </li>
            <li>
              <RouterLink
                v-if="userData.user_type === 'regular'"
                :to="{ name: 'parents_area' }"
                :class="{ active: parentsAreaInterface }"
              >
                <img :src="getNavIcon('parents-area')" alt="Parents Area" />
              </RouterLink>

              <RouterLink
                v-else
                :to="{ name: 'admin_area' }"
                :class="{ active: parentsAreaInterface }"
              >
                <img :src="getNavIcon('admin-area')" alt="Admin Area" />
              </RouterLink>
            </li>
          </template>
        </ul>
      </nav>

      <div ref="ratioChecker" class="ratio-checker" />

      <main
        :class="{
          'full-bleed': [
            'choose_student',
            'home',
            'my_music_room',
            'my_musician',
            'my_rooms',
            'music_games',
            'music_game_musical_memory_match',
            'music_game_music_studio_jr',
            'music_store',
            'music_store_collection',
            'music_store_category',
            'music_store_instrument',
          ].includes(String($route.name)),
          'parents-area': parentsAreaInterface,
        }"
      >
        <RouterView v-if="!parentsAreaInterface || parentPasswordEntered" />

        <template v-if="parentsAreaInterface">
          <ParentsAreaPasswordPrompt
            @authenticated="parentPasswordEntered = true"
            @unmounted="parentPasswordEntered = false"
          />

          <div v-if="parentPasswordEntered" class="parents-area-footer">
            <div>
              <a
                href="https://www.mymusicworkshop.com/terms-of-use"
                target="_blank"
                >Terms & Conditions</a
              >
              |
              <a
                href="https://www.mymusicworkshop.com/privacy-policy"
                target="_blank"
                >Privacy Policy</a
              >
              |
              <RouterLink :to="{ name: 'customer_support' }">
                Customer Support
              </RouterLink>
            </div>

            <RouterLink
              :to="{ name: 'logout' }"
              class="parents-area-log-out-link"
            >
              Log Out
              <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
            </RouterLink>
          </div>
        </template>
      </main>

      <footer
        ref="footer"
        :class="{
          'footer-only-one-nav-item': !showFooterNavigation,
        }"
      >
        <nav>
          <ul class="list-unstyled">
            <li>
              <RouterLink
                :to="{ name: 'home' }"
                :class="{ active: $route.name === 'home' }"
              >
                <img :src="getNavIcon('home')" alt="Home" />
              </RouterLink>
            </li>
            <template v-if="showFooterNavigation">
              <li
                v-for="section of Object.entries(mmwSections)"
                :key="`section${section[0]}`"
              >
                <RouterLink
                  :to="getFooterNavigationSectionLink(section[0])"
                  :class="{
                    active:
                      [
                        'lessons',
                        'worksheets',
                        'quizzes',
                        'jam_alongs',
                      ].includes(String($route.name)) &&
                      String($route.params.sectionSlug).toLowerCase() ===
                        section[0],
                  }"
                >
                  <img :src="getNavIcon(section[0])" :alt="section[1]" />
                </RouterLink>
              </li>
            </template>
          </ul>
        </nav>
      </footer>
    </div>

    <div v-if="resizeNeeded" class="resize-needed">
      <div>
        <p>
          <template v-if="resizeNeeded === 'mobile'">
            Please rotate your device into landscape mode.
          </template>

          <template v-else-if="resizeNeeded === 'safari_sidebar'">
            Please close your web browser's sidebar.
          </template>
        </p>

        <img
          v-if="resizeNeeded === 'mobile'"
          src="@/assets/rotate-device.svg"
          alt=""
        />
      </div>
    </div>
  </template>

  <main v-else class="simple-interface-main">
    <template
      v-if="
        !(
          (['signup', 'onboard'].includes(String($route.name)) &&
            $route.query.iframe === 'true') ||
          $route.name === 'questionnaire'
        )
      "
    >
      <img
        src="@/assets/logo.webp"
        alt="My Music Workshop"
        width="1000"
        height="440"
      />

      <nav
        v-if="!['onboard', 'onboard_school'].includes(String($route.name))"
        class="nav-pills"
      >
        <ul>
          <li><RouterLink :to="{ name: 'login' }">Log In</RouterLink></li>
          <li>
            <a href="https://www.mymusicworkshop.com/signup">Sign Up</a>
          </li>
        </ul>
      </nav>
    </template>

    <RouterView />
  </main>

  <ScrollbarSizeDetector />
</template>

<style lang="scss">
@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800";

:root {
  --red: #e74c3c;
  --red-a25: rgba(231, 76, 60, 0.25);
  --orange-lighter: #f9e1be;
  --orange-light: #f3ce98;
  --orange: #e98b1c;
  --orange-a50: rgba(233, 139, 28, 0.5);
  --orange-dark: #d7801a;
  --yellow: #f5c430;
  --yellow-a50: rgba(245, 196, 48, 0.5);
  --yellow-dark: #eab822;
  --green: #719c3c;
  --green-a50: rgba(113, 156, 60, 0.5);
  --green-dark: #689037;
  --blue-lightest: #d0e6f2;
  --blue-lighter: #b1d6ea;
  --blue-light: #54b1e5;
  --blue: #0080c8;
  --blue-a50: rgba(0, 128, 200, 0.5);
  --blue-dark: #0076b8;
  --blue-dark-a25: rgba(0, 118, 184, 0.25);
  --blue-darker: #01629a;
  --purple: #9856b4;
  --gray-lightest: #e9ecef;
  --gray-lighter: #dee2e6;
  --gray-light: #adb5bd;
  --gray: #6c757d;
  --gray-a50: rgba(108, 117, 125, 0.5);
  --gray-dark: #343a40;
  --body-color: #000;

  --sidebar-width: calc(57px + env(safe-area-inset-left));
  --footer-height: calc(56px + env(safe-area-inset-bottom));
  --nav-icon-size: 46px;
  --nav-icon-padding: 5px;
  --parents-area-header-height: 32px;
  --parents-area-footer-font-size: 0.8rem;

  &:not(:has(.simple-interface-main)) {
    scroll-padding-bottom: var(--footer-height);
  }

  :has(.resize-needed) dialog {
    display: none;
  }
}

.pswp__top-bar {
  left: env(safe-area-inset-left) !important;
  width: calc(
    100% - env(safe-area-inset-left) - env(safe-area-inset-right)
  ) !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: var(--body-color);
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  overflow-wrap: anywhere;

  &:not(:has(.first-route-not-reached-content)):not(
      :has(.simple-interface-main)
    ) {
    background-color: var(--blue-darker);
  }

  &:has(.my-music-room-content) {
    overflow: hidden;
  }
}

#app > div {
  --app-div-height: v-bind(appDivHeight);
  --desktop-padding-vertical-amount: v-bind(desktopPaddingVerticalAmount);
}

a {
  text-decoration: none;
  color: var(--blue);
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 32px;
  text-align: center;
}

p {
  margin: 0;
}

p,
.form-group,
.form-check,
.alert-container {
  &:after {
    // We are using these three lines on the :after pseudo-element rather than
    // having a bottom margin on the actual element so that it plays well with
    // the "TransitionSlide" component

    content: "";
    display: block;
    height: 1rem;
  }
}

.alert-container,
form,
.medium-width-centered {
  margin-inline: auto;
  width: 415px;
  max-width: 100%;
}

label {
  display: inline-block;
  margin-bottom: 0.4rem;
  color: var(--blue-dark);
  font-weight: 500;
  font-size: 17px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea,
.form-check-input,
.form-select,
.recurly-element {
  background-color: var(--gray-lighter);
  border: 1px solid var(--blue);

  &:focus {
    outline: 0;
    background-color: #fff;
    box-shadow: 0 0 0 0.2rem var(--box-shadow-color) !important;
  }

  &:hover:not(:focus):not(:checked) {
    background-color: #fff;
    border-color: var(--orange);
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea,
.form-select {
  font-size: 19px;
  font-weight: 300;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea {
  display: block;
  padding: 18px 25px;
  width: 100%;
  color: #000;
  transition: box-shadow 0.15s ease-in-out;
  --box-shadow-color: var(--blue-dark-a25);

  &.is-invalid {
    --box-shadow-color: var(--red-a25);
  }
}

textarea {
  height: 370px;
  max-height: 50vh;
}

fieldset {
  border: 1px solid var(--blue);

  &.is-invalid {
    border-color: var(--red);
  }

  legend {
    color: var(--orange-dark);
  }

  .invalid-feedback {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  :nth-last-child(1 of .form-check):after {
    display: none;
  }
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;

  .form-check-input {
    float: left;
    margin-left: -1.5em;
  }
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  --box-shadow-color: var(--blue-dark-a25);

  &[type="checkbox"] {
    border-radius: 0.25em;
  }

  &[type="radio"] {
    border-radius: 50%;
  }

  &:active {
    filter: brightness(90%);
  }

  &:focus {
    border-color: var(--blue-dark);
  }

  &:checked {
    background-color: var(--blue-dark);
    border-color: var(--blue-dark);
  }

  &:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  &:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
  }

  &[disabled] ~ .form-check-label,
  &:disabled ~ .form-check-label {
    opacity: 0.5;
  }
}

.form-select {
  display: block;
  width: 100%;
  padding: 18px 2.25rem 18px 0.75rem;
  font-weight: 400;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  color: inherit;
  -webkit-appearance: none;
  appearance: none;
  transition: box-shadow 0.15s ease-in-out;
  --box-shadow-color: var(--blue-dark-a25);

  &.is-invalid {
    --box-shadow-color: var(--red-a25);
  }
}

.form-text {
  display: block;
  margin-top: 0.5rem;
}

.invalid-feedback {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--red);
}

.recurly-element {
  margin-bottom: 1rem;
  height: 3.74em;
  background-color: #fff;
  --box-shadow-color: var(--blue-dark-a25);

  &.is-invalid {
    --box-shadow-color: var(--red-a25);
  }

  &.recurly-element-focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--box-shadow-color);
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.text-center {
  text-align: center !important;
}

.nav-pills {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 2rem;
    padding-left: 0;
    list-style: none;
  }

  li + li {
    margin-left: 1em;
  }

  a {
    display: block;
    padding: 0.5em 1em;
    border-radius: 18px;

    &.router-link-exact-active {
      background-color: var(--blue);
      color: #fff;
    }
  }
}

.parents-area-card {
  margin-inline: auto;
  padding: 20px;
  width: 866px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 32px;
}

.reload-message {
  position: fixed;
  top: calc(3.5rem + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  width: 555px;
  max-width: calc(90% - env(safe-area-inset-right) - env(safe-area-inset-left));
  z-index: 1;

  .alert {
    justify-content: center;
    background-color: #fff;
    text-align: center;
  }
}

.first-route-not-reached-content {
  .spinner,
  .launch-error-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .launch-error-content {
    width: calc(100% - 30px);
    max-width: 640px;

    .alert-container {
      width: auto;
    }
  }
}

.sidebar,
footer {
  ul {
    display: flex;
    margin: 0;
  }

  a,
  li > .btn-transparent {
    display: inline-block;
    line-height: 0;
    vertical-align: middle;

    &.active {
      filter: drop-shadow(0 0 10px var(--orange-dark));
    }
  }

  img {
    width: var(--nav-icon-size);
    height: var(--nav-icon-size);
  }
}

.sidebar {
  position: fixed;
  top: var(--desktop-padding-vertical-amount);
  bottom: var(--desktop-padding-vertical-amount);
  left: 0;
  background-color: var(--blue-light);
  z-index: 1;

  &.sidebar-ul-can-scroll-down:after,
  &.sidebar-ul-can-scroll-up:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 15px;
  }

  &.sidebar-ul-can-scroll-down:after {
    bottom: var(--footer-height);
    background-image: linear-gradient(
      to bottom,
      transparent 0,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  &.sidebar-ul-can-scroll-up:before {
    top: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0,
      transparent 100%
    );
  }

  ul {
    flex-direction: column;
    justify-content: space-between;
    width: var(--sidebar-width);
    height: calc(100% - var(--footer-height));
    background-color: var(--blue-lighter);
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  li {
    padding: var(--nav-icon-padding) var(--nav-icon-padding)
      var(--nav-icon-padding) 0;
    text-align: right;

    &:not(:last-child):not(:nth-last-child(2)) {
      padding-bottom: 0;
    }

    &:last-child {
      background-color: var(--blue-light);
      border-top: 1px dashed #000;
    }
  }
}

.ratio-checker {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - var(--sidebar-width));
  height: calc(100dvh - var(--footer-height));
  pointer-events: none;
}

main {
  &.simple-interface-main {
    padding: 1rem;
  }

  &:not(.simple-interface-main) {
    margin-top: var(--desktop-padding-vertical-amount);
    margin-left: var(--sidebar-width);
    height: calc(100dvh - calc(var(--desktop-padding-vertical-amount) * 2));
    background-color: #fff;

    &:not(.full-bleed) {
      padding: 1rem 1rem calc(var(--footer-height) + 1rem);
      overflow: auto;
    }

    &.parents-area {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: 1fr auto;
      background-color: var(--blue);

      header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -1rem -1rem 1rem;
        height: var(--parents-area-header-height);
        background-color: var(--blue-lighter);
        color: var(--blue-dark);

        .back-button {
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-50%);
          font-size: calc(var(--parents-area-header-height) * 0.33);
          text-transform: uppercase;
        }

        h1 {
          margin-bottom: 0;
          font-size: calc(var(--parents-area-header-height) * 0.64);
          font-weight: bold;
        }
      }
    }
  }
}

.parents-area-footer {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: var(--parents-area-footer-font-size);

  a {
    color: inherit;
  }
}

.parents-area-log-out-link {
  text-transform: uppercase;
}

.simple-interface-main img {
  display: block;
  margin-inline: auto;
  width: 500px;
  max-width: 100%;
  height: auto;
}

footer {
  position: fixed;
  right: 0;
  bottom: var(--desktop-padding-vertical-amount);
  left: 0;
  height: var(--footer-height);
  z-index: 1;

  &.footer-only-one-nav-item {
    width: var(--sidebar-width);
  }

  ul {
    container-type: inline-size;
    height: var(--footer-height);
    background-color: var(--orange-light);
  }

  li {
    padding-top: var(--nav-icon-padding);
    padding-inline: calc(
      calc(
          100cqi - 2px - calc(var(--nav-icon-size) * 10) - calc(
              var(--nav-icon-padding) + env(safe-area-inset-left)
            ) - calc(var(--nav-icon-padding) + env(safe-area-inset-right)) - var(
              --nav-icon-padding
            )
        ) / 17
    );

    &:first-child,
    &:nth-child(5) {
      border-right: 1px solid #000;
    }

    &:first-child {
      position: relative;
      padding-right: var(--nav-icon-padding);
      padding-left: calc(var(--nav-icon-padding) + env(safe-area-inset-left));
      background-color: #fff;

      &:before {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        left: 0;
        height: 1px;
        background-color: #000;
      }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      background-color: var(--orange-lighter);
    }

    &:last-child:not(:first-child) {
      padding-right: calc(var(--nav-icon-padding) + env(safe-area-inset-right));
    }
  }
}

.resize-needed {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  background-color: #fff;
  z-index: 1;

  div {
    padding: 1rem;
  }

  p {
    margin-bottom: 0;
    text-align: center;
  }

  img {
    margin-top: 1rem;
    width: 100%;
  }
}

// hover bouncy style
#app {
  --bounce-x: 8px;
  --bounce-y: 8px;
  --bounce-scale: 0.06;
}
@keyframes bouncy {
  0% {
    transform: translate(0, 0) scale(1);
  }
  33% {
    transform: translate(0, 0)
      scale(calc(1 + var(--bounce-scale)), calc(1 - var(--bounce-scale)));
  }
  66% {
    transform: translate(0, 0) scale(calc(1), calc(1 + var(--bounce-scale)));
  }
}
.sidebar,
footer {
  a,
  li > .btn-transparent {
    transition: transform 200ms;
  }
}
.sidebar {
  ul {
    overflow-x: hidden;
  }
}
footer ul {
  overflow-y: hidden;
}
@media (any-hover: hover) {
  .sidebar,
  footer {
    a:hover,
    li > .btn-transparent:hover {
      transform: translate(var(--bounce-x), calc(-1 * var(--bounce-y)))
        scale(calc(1 + var(--bounce-scale)));

      img {
        animation: bouncy 1.2s infinite;
      }
    }
  }
  .sidebar {
    a:hover,
    li > .btn-transparent:hover {
      --bounce-x: 6px;
      --bounce-y: 0px;
    }
  }
  footer {
    a:hover {
      --bounce-x: 0px;
      --bounce-y: 6px;
    }
    li:first-child a:hover {
      --bounce-x: 6px;
      --bounce-y: 6px;
    }
  }
}

@media (min-width: 1024px) {
  :root {
    --sidebar-width: calc(126px + env(safe-area-inset-left));
    --footer-height: calc(125px + env(safe-area-inset-bottom));
    --nav-icon-size: 95px;
    --nav-icon-padding: 15px;
    --parents-area-header-height: 75px;
    --parents-area-footer-font-size: 1.2rem;
  }
}
@media (any-hover: hover) and (width < 1024px) {
  .sidebar {
    a:hover,
    li > .btn-transparent:hover {
      --bounce-x: 2px;
      --bounce-y: 0px;
    }
  }
  footer {
    a:hover {
      --bounce-x: 0px;
      --bounce-y: 2px;
    }
    li:first-child a:hover {
      --bounce-x: 2px;
      --bounce-y: 2px;
    }
  }
}

@media (width < 667px) {
  .parents-area-card {
    padding: 15px;
  }
}

@media (width < 768px) {
  h1 {
    font-size: 22px;
  }
}
</style>
